<template>
  <Chat
    :refId="quoteId"
    :editable="true"
    :chatDashboard="true"
    :contractorId="userId"
    :companyId="sessionCompanyId"
  />
</template>
<script setup>
import { provide, computed } from 'vue'
import { useStore } from 'vuex'
import Chat from '@/components/chat/Chat.vue'

const store = useStore()
const company = computed(() => store.state.session.company)
const user = computed(() => store.state.session.user)
const sessionCompanyId = computed(() => company.value.company_id)
const userId = computed(() => user.value.user_id)
const quoteId = computed(() => store.state.Quote.quote_id)
</script>
